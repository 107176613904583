<template>
  <div class="footer">
    <div class="top">
      <div class="container">
        <div class="top-warpper">
          <div class="logo" @click="goHome">
            <img src="~@/assets/images/logo_2.png" alt="" />
          </div>
          <a href="tel:1300 849 879">
            <div class="phone">
              <img src="~@/assets/images/phone.png" alt="" />
              <span>1300 849 879</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="main-warpper">
          <div class="nav">
            <div class="nav-title">Navigation</div>
            <div class="nav-list">
              <div
                class="nav-itm"
                v-for="(item, index) in navList"
                :key="item.id"
                @click="goNav(index, item.name)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="page">
            <div class="page-title">We Buy Cars in</div>
            <div class="page-info">
              <div
                class="page-item"
                v-for="item in pageList"
                :key="item.id"
                @click="goPage(item.id, item.allName)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="page">
            <div class="page-title">Finance Your Next Car</div>
            <a href="https://www.carsfinance.com.au/">
              <img src="~@/assets/images/logo3.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="copy">
          © 2018 - 2025 Selling Your Car Value.&nbsp;&nbsp;ABN 20 165 884
          275,&nbsp;&nbsp;Dealer License:&nbsp;&nbsp;4203457
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          name: "Location",
        },
        {
          id: 2,
          name: "About",
        },
        {
          id: 3,
          name: "FAQ",
        },
        {
          id: 4,
          name: "Get Started",
        },
      ],
      pageList: [
        {
          id: 4,
          name: "QLD",
          allName: "Queensland",
        },
        {
          id: 8,
          name: "WA",
          allName: "Western Australia",
        },
        {
          id: 2,
          name: "NSW",
          allName: "New South Wales",
        },
        {
          id: 1,
          name: "ACT",
          allName: "Australian Capital Territory",
        },
        {
          id: 7,
          name: "VIC",
          allName: "Victoria",
        },
        {
          id: 6,
          name: "TAS",
          allName: "Tasmania",
        },
        {
          id: 5,
          name: "SA",
          allName: "South Australia",
        },
        {
          id: 3,
          name: "NT",
          allName: "Northern Territory",
        },
      ],
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    goNav(index, path) {
      if (index != 3) {
        this.$router.push({ name: path, query: { index } });
      } else {
        this.$router.push("/start-sell-your-car");
      }
    },
    goPage(id, name) {
      this.$router.push({
        name: "Kong",
        query: { path: "/location-sell-your-car", index: 0, id, name },
      });
    },
  },
};
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  background: #0c61b9;

  .top {
    border-bottom: 1px #54cbff solid;

    .top-warpper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        width: 300px;
        height: 85px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          width: 50%;
          height: 66%;
        }
      }

      .phone {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: Ubuntu-Regular, Ubuntu;
        font-weight: 600;
        color: #ffffff;
        line-height: 21px;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }

  .main {
    border-bottom: 1px #54cbff solid;

    .main-warpper {
      display: flex;
      padding: 40px 0;
      box-sizing: border-box;

      .nav {
        .nav-title {
          font-size: 24px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #ffffff;
          line-height: 28px;
          margin-bottom: 24px;
          user-select: none;
        }

        .nav-list {
          .nav-itm {
            margin-bottom: 12px;
            font-size: 16px;
            font-family: Ubuntu-Regular, Ubuntu;
            font-weight: 400;
            color: #ffffff;
            line-height: 19px;
            user-select: none;
            cursor: pointer;
          }
        }
      }

      .page {
        margin-left: 300px;

        .page-title {
          width: 100%;
          margin-bottom: 24px;
          font-size: 24px;
          font-family: Ubuntu-Bold, Ubuntu;
          font-weight: bold;
          color: #ffffff;
          line-height: 28px;
          user-select: none;
        }

        img {
          width: 235px;
          height: 40px;
          background: #fff;
          padding: 8px;
        }

        .page-info {
          width: 100%;
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          font-size: 16px;
          font-family: Ubuntu-Regular, Ubuntu;
          font-weight: 400;
          color: #ffffff;
          line-height: 19px;

          .page-item {
            width: 50%;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  .copyright {
    padding: 20px;
    box-sizing: border-box;

    .copy {
      font-family: Ubuntu-Regular;
      font-weight: 400;
      font-size: 12px;
      color: #fff;
      line-height: 14px;
    }
  }
}

@media only screen and (max-width: 690px) {
  .footer {
    .top {
      .top-warpper {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0;
        box-sizing: border-box;

        .logo {
          img {
            width: 35%;
            height: 46%;
          }
        }
      }
    }

    .main {
      .main-warpper {
        flex-direction: column;
        padding: 20px 0;

        .nav {
          .nav-title {
            font-size: 16px;
            line-height: 19px;
          }

          .nav-list {
            .nav-itm {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }

        .page {
          margin-left: 0;
          margin-top: 20px;

          .page-title {
            font-size: 16px;
            line-height: 19px;
          }

          .page-info {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .footer {
    .top {
      .top-warpper {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0;
        box-sizing: border-box;

        .logo {
          img {
            width: 35%;
            height: 46%;
          }
        }
      }
    }

    .main {
      .main-warpper {
        flex-direction: column;
        padding: 20px 0;

        .nav {
          .nav-title {
            font-size: 16px;
            line-height: 19px;
          }

          .nav-list {
            .nav-itm {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }

        .page {
          margin-left: 0;
          margin-top: 20px;

          .page-title {
            font-size: 16px;
            line-height: 19px;
          }

          .page-info {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
</style>
